<template>
    <div class="shoppPay">
        <div class="shoppPay-header">
            <div class="warp">
                <div class="logo">
                    <div class="img" @click="$router.push('/index')" style="width: 126px;height: 26px;">
                        <img src="@/assets/images/goods/soyego.png" />
                    </div>
                    <div class="pay">
                        <div class="img"><img src="@/assets/images/goods/Lock.png" /></div>
                        <span>{{translateTextData.SECURE_PAYMENT}}</span>
                    </div>
                </div>
                <div class="text">
                    <span>{{translateTextData.Continue_Shopping}}</span>
                    <img src="@/assets/images/goods/r.png" />
                </div>
            </div>
        </div>
        <div class="shoppPay-order">
            <div class="warp">
                <div class="order-top">
                    <div class="text complete">
                        <div class="img"><img src="@/assets/images/goods/bag.png" /></div>
                        <span>{{translateTextData.BAG}}</span>
                    </div>
                    <div class="line complete-line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text ative">
                        <div class="img"><img src="@/assets/images/goods/order1.png" /></div>
                        <span>{{translateTextData.Submit_Order}}</span>
                    </div>
                    <div class="line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text">
                        <div class="img"><img src="@/assets/images/goods/payment.png" /></div>
                        <span>{{translateTextData.Payment}}</span>
                    </div>
                    <div class="line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text">
                        <div class="img"><img src="@/assets/images/goods/complete.png" /></div>
                        <span>{{translateTextData.Complete}}</span>
                    </div>
                </div>
                <div class="order-main">
                    <div class="main-left">
                        <div class="left-address">
                            <h5>{{translateTextData.Shipping_Address}}</h5>
                            <div class="swiper" v-if="shoppPayList.length > 0" v-loading="addressLoad">
                                <swiper :options="shoppPayOption" ref="shoppPaySwiper">
                                    <swiper-slide v-for="(item, index) in shoppPayList" :key="index">
                                        <div class="textAddress">
                                            <h5>{{ item.name }}</h5>
                                            <span>{{ item.telephone }}</span>
                                            <p>{{ item.full_address }}</p>
                                            <div>
                                                <el-radio v-if="item.is_default == 1" v-model="Default" :label="item.is_default" @change="radioChange(item)">Default</el-radio>
                                                <el-radio v-else v-model="Default" :label="index + 2" @change="radioChange(item)">Default</el-radio>
                                                <div>
                                                    <img src="@/assets/images/goods/edit.png" @click="editClick(index)" />
                                                </div>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide>
                                        <div class="add" @click="addressAdd">
                                            <h5>+ Add New Address</h5>
                                        </div>
                                    </swiper-slide>
                                    <div class="swiper-pagination-1" style="text-align: center" slot="pagination"></div>
                                </swiper>
                            </div>
                            <div v-else class="textEmpty" @click="addressAdd" v-loading="addressLoad">
                                <p>+ Shipping Address</p>
                                <span>{{translateTextData.You_have_not_selected_the_delivery_address}}</span>
                            </div>
                        </div>
                        <div class="left-list">
                            <h5>{{translateTextData.Item}} ({{ shoppProductList.length }})</h5>
                            <div class="list">
                                <div class="item" v-for="(item, index) in shoppProductList" :key="index">
                                    <div class="img">
                                        <el-image :lazy-src="item.imgUrl" :src="item.imgUrl" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        <!-- <img :src="item.imgUrl"> -->
                                    </div>
                                    <div class="text">
                                        <p>{{ item.goods_name }}</p>
                                        <div class="size">
                                            <span>{{translateTextData.Color}}: {{ item.colorAtiveName }}</span>
                                            <span v-if="item.sizeAtive">{{translateTextData.Size}}: {{ item.sizeAtive }}</span>
                                            <span v-else>&nbsp;</span>
                                        </div>
                                        <b>{{ item.strCurXSoyego }}{{ item.discount_price }}</b>
                                    </div>
                                    <div class="sum">
                                        <span>x{{ item.num }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-logistics">
                            <h5>{{translateTextData.Logistics_Methods}}</h5>
                            <div class="shippingItem">
                                <div class="text">
                                    <span>{{translateTextData.Shipping_Info}}</span>
                                    <!-- <p><b>{{ countrySoyego }}{{ strCurXSoyego }}{{ transport.delivery_money }}</b> Order placed now are expected to arrived before <span>{{ transport.delivery_start_day }} - {{ transport.delivery_end_day }} day</span></p> -->
                                    <p><b>{{ countrySoyego }}{{ strCurXSoyego }}{{ transport.delivery_money }}</b> {{translateTextData.Order_placed_now_are_expected_to_arrived_before}} <span>14 {{translateTextData.day}}</span></p>
                                    <!-- <p v-if="parseFloat(retailPrice) < 99"><b>{{ countrySoyego }}{{ strCurXSoyego }}{{ transport.delivery_money }}</b> Order placed now are expected to arrived before <span>14 day</span></p> -->
                                    <!-- <p v-if="parseFloat(retailPrice) >= 99"><b>{{ countrySoyego }}{{ strCurXSoyego }}0</b> Order placed now are expected to arrived before <span>14 day</span></p> -->
                                    <!-- <div>
                                        <span>{{ countrySoyego }}{{ strCurXSoyego }}5.90</span>
                                        <p>Order placed now are expected to arrived before <span>Thursday, Jan 20 - Saturday, Jan 22.</span></p>
                                    </div> -->
                                </div>
                            </div>
                            <!-- <div class="shipping ative">
                                <div class="img"><img src="@/assets/images/goods/radio1.png" /></div>
                                <div class="text">
                                    <span>Express Shipping</span>
                                    <div>
                                        <span>{{ countrySoyego }}{{ strCurXSoyego }}12.90</span>
                                        <p>Order placed now are expected to arrived before <span>Friday, Jan 17 - Monday, Jan 19.</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="shipping">
                                <div class="img"><img src="@/assets/images/goods/radio.png" /></div>
                                <div class="text">
                                    <span>Standrad Shipping</span>
                                    <div>
                                        <span>{{ countrySoyego }}{{ strCurXSoyego }}5.90</span>
                                        <p>Order placed now are expected to arrived before <span>Thursday, Jan 20 - Saturday, Jan 22.</span></p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="left-payment">
                            <h5>{{translateTextData.Payment_Method}}</h5>
                            <!-- 余额付款 -->
                            <!-- <div class="item">
                                <div class="radio">
                                    <img v-if="radioShow == 1" src="@/assets/images/goods/radio1.png" @click="radioShow = -1" />
                                    <img v-else src="@/assets/images/goods/radio.png" @click="radioShow = 2" />
                                </div>
                                <div class="img">
                                    <el-image :lazy-src="require('@/assets/images/goods/paypal.png')" :src="require('@/assets/images/goods/paypal.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                </div>
                                <span>PayPal</span>
                            </div> -->
                            <div class="item">
                                <div class="radio">
                                    <img v-if="radioShow == 0" src="@/assets/images/goods/radio1.png" @click="radioShow = -1" />
                                    <img v-else src="@/assets/images/goods/radio.png" @click="radioShow = 0" />
                                </div>
                                <div class="img">
                                    <el-image :lazy-src="require('@/assets/images/goods/creadit.png')" :src="require('@/assets/images/goods/creadit.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                </div>
                                <div class="text">
                                    <span>Credit/Debit Card</span>
                                    <div class="textImg">
                                        <div class="img">
                                            <el-image :lazy-src="require('@/assets/images/goods/visa.png')" :src="require('@/assets/images/goods/visa.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :lazy-src="require('@/assets/images/goods/mastercard.png')" :src="require('@/assets/images/goods/mastercard.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :lazy-src="require('@/assets/images/goods/图层 601.png')" :src="require('@/assets/images/goods/图层 601.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :lazy-src="require('@/assets/images/goods/club.png')" :src="require('@/assets/images/goods/club.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :lazy-src="require('@/assets/images/goods/union pay.png')" :src="require('@/assets/images/goods/union pay.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :lazy-src="require('@/assets/images/goods/jcb.png')" :src="require('@/assets/images/goods/jcb.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :lazy-src="require('@/assets/images/goods/cb.png')" :src="require('@/assets/images/goods/cb.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :lazy-src="require('@/assets/images/goods/InteracLogo.svg.png')" :src="require('@/assets/images/goods/InteracLogo.svg.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="radio">
                                    <img v-if="radioShow == 1" src="@/assets/images/goods/radio1.png" @click="radioShow = -1" />
                                    <img v-else src="@/assets/images/goods/radio.png" @click="radioShow = 1" />
                                </div>
                                <div class="img">
                                    <el-image :lazy-src="require('@/assets/images/goods/paypal.png')" :src="require('@/assets/images/goods/paypal.png')" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                </div>
                                <span>PayPal</span>
                            </div>
                            <!-- <div class="item" v-for="(item, index) in paymentMethod" :key="index" style="padding-top: 30px;">
                                <div class="radio">
                                    <img v-if="radioShow == item.radio" src="@/assets/images/goods/radio1.png" @click="radioShow = -1" />
                                    <img v-else src="@/assets/images/goods/radio.png" @click="radioShow = index + 1" />
                                </div>
                                <div class="img">
                                    <el-image :lazy-src="$util.img(item.image.logo)" :src="$util.img(item.image.logo)" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                                </div>
                                <span>{{ item.pay_type.pay_type }}</span>
                            </div> -->
                        </div>
                    </div>
                    <div class="main-right">
                        <!-- <div class="right-top">
                            <div class="email">
                                <h5><span>*</span>Your Email</h5>
                                <div class="text">
                                    <input type="text" v-model="email" />
                                    <button>Subscribe</button>
                                </div>
                                <p>Please enter your email to ensure the offer is delivered to your account</p>
                                <span>*Enter your email to get more discounts.</span>
                                <p>*We will send your login information to your Email address.</p>
                            </div>
                        </div> -->
                        <div class="right-top">
                            <!-- <p><span>*</span> ‘Coupon’ and ‘Promo code’ cannot be applied at the same time</p> -->
                            <div class="code">
                                <h5>{{translateTextData.Promo_Code}}</h5>
                                <div class="text">
                                    <input type="text" :placeholder="translateTextData.Promo_Code" v-model="promoCode" @keydown="keyDownClick" />
                                    <span>{{ code }}</span>
                                    <button @click="codeClick">{{translateTextData.Verify}}</button>
                                </div>
                                <p :class="codeTips ? 'pShow' : ''">{{translateTextData.Invalid_code}}</p>
                            </div>
                            <!-- <div class="coupon">
                                <h5>Coupon</h5>
                                <div class="text" @click="coupons = true">
                                    <input type="text" disabled placeholder="Coupon" v-model="couponSave" />
                                    <img src="@/assets/images/goods/r.png" />
                                </div>
                            </div> -->
                        </div>                        
                        <div class="right-bottom">
                            <div class="order">
                                <h5>{{translateTextData.Order_Summary}}</h5>
                                <div>
                                    <span>{{translateTextData.Retail_Price}}</span>
                                    <p>{{ strCurXSoyego }}{{ retailPrice }}</p>
                                </div>
                                <div>
                                    <span>{{translateTextData.Logistics}}</span>
                                    <p>{{ strCurXSoyego }}{{ transport.delivery_money }}</p>
                                    <!-- <p v-if="parseFloat(retailPrice) < 99">{{ strCurXSoyego }}{{ transport.delivery_money }}</p> -->
                                    <!-- <p v-if="parseFloat(retailPrice) >= 99">{{ strCurXSoyego }}0.00</p> -->
                                </div>
                                <div v-show="codeMoneyShow">
                                    <span>{{translateTextData.Coupon}}</span>
                                    <p><b>-{{ strCurXSoyego }}{{ codeMoney }}</b></p>
                                </div>
                            </div>
                            <div class="total">
                                <span>{{translateTextData.Total}}</span>
                                <p>{{ strCurXSoyego }} {{ (parseFloat(retailPrice) + (parseFloat(transport.delivery_money)) - parseFloat(codeMoney)).toFixed(2) }}</p>
                                <!-- <p>{{ strCurXSoyego }}{{ (parseFloat(retailPrice) + (parseFloat(transport.delivery_money)) - parseFloat(codeMoney)).toFixed(2) }}</p> -->
                            </div>
                            <button @click="paymentClick">{{translateTextData.PAY_NOW}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :title="addressTitle" :visible.sync="shipingAddress" @closed="handleClose">
            <ShipingAddress :addressAtive="ative" :addressList="shipingAddressList" @closeDialog="handleClose" @addAddress="addAddressShow"></ShipingAddress>
            <!-- <ShipingAddress @closeDialog="handleClose" @addAddress="addAddressShow" :addressAtive="addressAtiveText" :addressList="shipingAddressList"></ShipingAddress> -->
        </el-dialog>

        <el-dialog title="Coupons" :visible.sync="coupons" @closed="handleClose1">
            <ShipingCoupons @radioClose="radioClose"></ShipingCoupons>
        </el-dialog>
    </div>
</template>

<style>
.shoppPay .shoppPay-order .swiper-container {
    height: 100%;
}
</style>
<style lang="scss" scoped>
@import "./css/shoppPay.scss";
</style>

<script>
import shoppPay from "./js/shoppPay.js"
import ShipingAddress from "./components/shipingAddress.vue"
import ShipingCoupons from "./components/shipingCoupons.vue"

export default {
    name: "shoppPay",
    components: {
        ShipingAddress,
        ShipingCoupons
    },
    mixins: [shoppPay]
}
</script>
