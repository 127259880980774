import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import { addressList, setDefault } from "@/api/member/member"
import { payPcType, memberaddressEdit, diyviewInfo, getPlatformcouponTypelists, judgePlatformcoupon, getPlatformcouponReceive, getPlatformcouponId } from "@/api/pc"
import { orderCreate, calculate } from "@/api/order/payment"

import { pay } from "@/api/pay"
import { _ } from 'core-js'

export default {
	name: "shoppPay",
	components: {
		Swiper,
		SwiperSlide,
	},
	data: () => {
		return {
			shipingAddress: false,
			coupons: false,
			Default: 1,
			shoppPayOption: {
				slidesPerView: 2,
				spaceBetween: 10,
				slidesPerGroup: 2
			},
			couponSave: "",
			promoCode: "",
			code: "",
			shoppPayList: [],
			shoppProductList: [],
			retailPrice: 0,
			logistics: 0,
			total: 0,
			strCurXSoyego: localStorage.getItem("strCurXSoyego"),
			countrySoyego: localStorage.getItem('countrySoyego'),
			paymentMethod: [],
			radioShow: -1,
			radioId: 0,
			ative: 0,
			shipingAddressList: {},
			addressTitle: "",
			num: 0,
			transport: {
				delivery_end_day: 0,
				delivery_money: 0,
				delivery_start_day: 0,
			},
			discountCode: "",
			codeTips: false,
			codeMoney: 0,
			codeMoneyShow: false,
			addressStr: "",
			addressLoad: true,
			email: "",
			couponId: 0,
			promotion_coupon: '',
			promotion_code: '',
			// 需要翻译的静态文字
            translateTextData: {
				SECURE_PAYMENT: 'SECURE PAYMENT',
				Continue_Shopping: 'Continue Shopping',
				BAG: 'BAG',
				Submit_Order: 'Submit Order',
				Payment: 'Payment',
				Complete: 'Complete',
				Shipping_Address: 'Shipping Address',
				You_have_not_selected_the_delivery_address: 'You have not selected the delivery address',
				Item: 'Item',
				Color: 'Color',
				Size: 'Size',
				Logistics_Methods: 'Logistics Methods',
				Shipping_Info: 'Shipping Info',
				Order_placed_now_are_expected_to_arrived_before: 'Order placed now are expected to arrived before',
				day: 'day',
				Payment_Method: 'Payment Method',
				Promo_Code: 'Promo Code',
				Verify: 'Verify',
				Invalid_code: 'Invalid code',
				Order_Summary: 'Order Summary',
				Retail_Price: 'Retail Price',
				Logistics: 'Logistics',
				Coupon: 'Coupon',
				Total: 'Total',
				PAY_NOW: 'PAY NOW'
            }
		}
	},
	watch: {
		'$route': 'fetchData',
		// shoppPayList: {
		// 	deep: true,
		//     handler: function (val) {
		//         this.shoppPayList = val;
		// 		this.fetchData();
		//     },
		// }
	},
	created() {
		this.getTranslateText()
	},
	mounted() {
		this.getMemberAddress();
		// this.getDiyviewInfo();
		if (this.$route.query.data == "cart") {
			this.getList();
		} else {
			this.getItem();
		}
		this.getPay();
		// this.fetchData();
	},
	computed: {
		swiper() {
			return this.$refs.shoppPaySwiper.swiper
		}
	},
	methods: {
		// 翻译静态文本
        getTranslateText() {
            let strText = '';
            let i = 0;
            let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
            translateData.forEach((item,index) => {
                strText += item + '\n'
            })
            // 翻译文本
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    for(let key in this.translateTextData){
                        this.translateTextData[key] = resData[i];
                        i++;
                    }
                }
            })
        },
		keyDownClick(e) {
            if(e.keyCode == 13){
                this.codeClick();
            }
        },
		// getDiyviewInfo() {
		// 	const params = {
		// 		name: "DIY_VIEW_INDEX",
		// 		website_id: 0
		// 	}
		// 	var that = this;
		// 	diyviewInfo(params || {})
		// 		.then(res => {
		// 			let data = JSON.parse(res.data.value);
		// 			that.discountCode = "SOYEGO15";
		// 			// console.log(data)
		// 			// let value = data.value;
		// 			// value.forEach(item => {
		// 			// 	if(item.controller == "TextCode"){
		// 			// 		that.discountCode = item.code;
		// 			// 	}
		// 			// })
		// 		})
		// 		.catch(err => {
		// 			// console.log(err)
		// 		})
		// },
		getList() {
			console.log('从购物车进来')
			let list = localStorage.getItem("shoppProductCartSoyego");
			let listArr = list.substring(0, list.lastIndexOf(',')).split(",")
			// var indexList = localStorage.getItem("shoppProductCartsiteIdSoyego").split(",");
			calculate({
				barterRate: this.countrySoyego,
				cart_ids: list,
				barterRate: this.countrySoyego
				// member_address: this.addressStr
			}).then(res => {
				if (res.code == 0 && res.data) {
					this.num = res.data.goods_num;
					this.retailPrice = parseFloat(res.data.goods_money).toFixed(2); //商品价格
					// if(this.retailPrice < 99) {
					this.transport.delivery_money = parseFloat(res.data.delivery_money).toFixed(2); // 运费
					let that = this;
					let j = 0;
					let x = 0;
					let goodsList = res.data.shop_goods_list;
					let strName = '';
					goodsList.forEach((item,index) => {
						item.goods_list.forEach((t,k) => {
							var productDetail = {
								goods_name: '',
								discount_price: 0,
								strCurXSoyego: localStorage.getItem("strCurXSoyego"),
								countrySoyego: localStorage.getItem('countrySoyego'),
								colorAtiveName: '',
								sizeAtive: '',
								num: 0,
								imgUrl: '',
								id: 0,
							};
							strName += t.goods_name + '\n';
							productDetail.goods_name = t.goods_name;
							productDetail.discount_price = (parseFloat(t.goods_money)).toFixed(2);
							productDetail.num = t.num;
							productDetail.imgUrl = t.goods_image.split(",")[0];
							productDetail.id = t.sku_id;
							let sizeColor = JSON.parse(t.sku_spec_format);
							sizeColor.forEach(item => {
								if (item.spec_name == "Colour" || item.spec_name == "Color") {
									productDetail.colorAtiveName = item.spec_value_name;
								}
								if (item.spec_name == "Size") {
									productDetail.sizeAtive = item.spec_value_name;
								}
							});
							that.shoppProductList[j] = productDetail;
							j = j + 1;
						})
					})
					// 翻译 商品名称
                    this.$fanyi.translateData({
                        query: strName,
                        success: resData => {
							that.shoppProductList.forEach((item,index) => {
								that.shoppProductList[index].goods_name = resData[index]
							})
                        }
                    })
				}
			}).catch(err => {
				// console.log(err)
			})
			// this.shoppProductList = list;
			// console.log(JSON.parse(localStorage.getItem("shoppProductSoyego")))
		},
		getItem() {
			console.log('不从购物车进来')
			let obj = JSON.parse(localStorage.getItem("shoppProductSoyego"));
			calculate({
				barterRate: this.countrySoyego,
				sku_id: parseInt(obj.id),
				num: parseInt(obj.num)
				// color: obj.colorAtiveName,
				// size: obj.sizeAtive
				// member_address: this.addressStr
			}).then(res => {
				if (res.code == 0 && res.data) {
					this.num = res.data.goods_num;
					this.retailPrice = parseFloat(res.data.goods_money).toFixed(2);
					// if(this.retailPrice < 99) {
					this.transport.delivery_money = parseFloat(res.data.delivery_money).toFixed(2);
					// }else{
					// 	this.transport.delivery_money = (0.00);
					// }
					// this.total = (parseFloat(this.retailPrice) + parseFloat(this.logistics)).toFixed(2);
					var k = 0;
					var that = this;
					for (var index in res.data.shop_goods_list) {
						var productDetail = {
							goods_name: '',
							discount_price: 0,
							strCurXSoyego: localStorage.getItem("strCurXSoyego"),
							countrySoyego: localStorage.getItem('countrySoyego'),
							colorAtiveName: '',
							sizeAtive: '',
							num: 0,
							imgUrl: '',
							id: 0,
						};
						
						productDetail.goods_name = res.data.shop_goods_list[index].goods_list[k].goods_name;
						productDetail.discount_price = (parseFloat(res.data.shop_goods_list[index].goods_list[k].goods_money)).toFixed(2);
						productDetail.num = res.data.shop_goods_list[index].goods_list[k].num;
						productDetail.imgUrl = res.data.shop_goods_list[index].goods_list[k].goods_image.split(",")[0];
						productDetail.id = res.data.shop_goods_list[index].goods_list[k].sku_id;

						let sizeColor = JSON.parse(res.data.shop_goods_list[index].goods_list[k].sku_spec_format);

						sizeColor.forEach(item => {
							if (item.spec_name == "Colour" || item.spec_name == "Color") {
								productDetail.colorAtiveName = item.spec_value_name;
							}
							if (item.spec_name == "Size") {
								productDetail.sizeAtive = item.spec_value_name;
							}
						});
						that.shoppProductList[k] = productDetail;
						k = k + 1;
					}
					// 翻译 商品名称
					let strText = '';
					for(let j in this.shoppProductList){
						strText += this.shoppProductList[j].goods_name
					}
					this.$fanyi.translateData({
						query: strText,
						success: resData => {
							if(typeof resData == 'string'){
								this.shoppProductList[0].goods_name = resData
							}else{
								for(let x in this.shoppProductList){
									this.shoppProductList[x].goods_name = resData[x]
								}
							}
						}
					})
				}
			}).catch(err => {
				// console.log(err)
			})
		},
		getPay() {
			payPcType().then(res => {
				if (res.code == 0 && res.data) {
					this.paymentMethod = res.data;
					for (let i = 0; i < this.paymentMethod.length; i++) {
						let j = i + 1;
						this.$set(this.paymentMethod[i], 'radio', j)
					}
				}
			}).catch(err => {
				// console.log(err)
			})
		},
		getMemberAddress() {
			addressList({
				page_size: 0
			}).then(res => {
				const {
					code,
					message,
					data
				} = res
				if (data && data.list) {
					this.shoppPayList = data.list;
					this.addressEmpty = true;
					this.addressLoad = false;
				}
			}).catch(err => {
				const {
					code,
					message,
					data
				} = err
				this.$message.error(message)
				this.addressLoad = false;
			})
		},
		addAddressShow(val) {
			if (val == 0) {
				this.getMemberAddress();
				if (this.$route.query.data == "cart") {
					this.getList();
				} else {
					this.getItem();
				}
				this.getPay();
			}
		},
		fetchData() {
			this.getMemberAddress();
			if (this.$route.query.data == "cart") {
				this.getList();
			} else {
				this.getItem();
			}
			this.getPay();
			this.$forceUpdate();
		},
		editClick(i) {
			this.addressTitle = "Edit Address";
			this.shipingAddress = true;
			this.ative = 1;
			this.shipingAddressList = this.shoppPayList[i];
			this.$forceUpdate();
		},
		addressAdd() {
			this.addressTitle = "Add New Address";
			this.shipingAddress = true;
			this.ative = 0;
			this.shipingAddressList = {};
			this.$forceUpdate();
		},
		handleClose() {
			this.shipingAddress = false;
		},
		handleClose1() {
			this.coupons = false;
		},
		radioClose(val) {
			this.coupons = false;
			this.couponSave = "-US$" + val;
		},
		getCodeList() {
			let list = localStorage.getItem("shoppProductCartSoyego");
			var indexList = localStorage.getItem("shoppProductCartsiteIdSoyego").split(",");
			calculate({
				barterRate: this.countrySoyego,
				cart_ids: list,
				promotion_code: this.promoCode
				// member_address: this.addressStr
			}).then(res => {
				console.log(3)
				if (res.code == 0 && res.data) {
					let data = res.data;
					let total = (parseFloat(data.goods_money) + parseFloat(data.delivery_money)).toFixed(2);
					// if(data.pay_money == total){
					// 	this.code = "";
					// 	this.codeTips = true;
					// 	this.codeMoneyShow = false;
					// 	this.promotion_code = data.promotion_code;
					// 	this.promotion_coupon = data.promotion_coupon;
					// }else if(data.pay_money < total){
					// 	// let text = (parseFloat(data.promotion_code_money / data.goods_money).toFixed(2)) * 100;
					// 	let text = data.discount.toFixed(2) * 100;
					// 	this.code = "-" + text + "%OFF";
					// 	this.codeMoney = data.promotion_code_money;
					// 	this.codeTips = false;
					// 	this.codeMoneyShow = true;
					// 	this.promotion_code = data.promotion_code;
					// 	this.promotion_coupon = data.promotion_coupon;
					// }
					// let text = (parseFloat(data.promotion_code_money / data.goods_money).toFixed(2)) * 100;
					let text = data.discount.toFixed(2) * 100;
					this.code = "-" + text + "%OFF";
					this.codeMoney = data.promotion_money.toFixed(2);
					this.codeTips = false;
					this.codeMoneyShow = true;
					this.promotion_code = data.promotion_code;
					this.promotion_coupon = data.promotion_coupon;
				}
			}).catch(err => {
				// console.log(err)
			})
		},
		getCodeItem() {
			let obj = JSON.parse(localStorage.getItem("shoppProductSoyego"));
			calculate({
				barterRate: this.countrySoyego,
				sku_id: parseInt(obj.id),
				num: parseInt(obj.num),
				promotion_code: this.promoCode,
				// promotion_coupon: this.promoCode
			}).then(res => {
				console.log(4)
				if (res.code == 0 && res.data) {
					let data = res.data;
					let total = (parseFloat(data.goods_money) + parseFloat(data.delivery_money)).toFixed(2);
					
					// if(data.pay_money == total){
					// 	this.code = "";
					// 	this.codeMoney = 0;
					// 	this.codeTips = true;
					// 	this.codeMoneyShow = false;
					// 	this.promotion_code = data.promotion_code;
					// 	this.promotion_coupon = data.promotion_coupon;
					// }else if(data.pay_money < total){
					// 	let text = (parseFloat(data.promotion_code_money / data.goods_money).toFixed(2)) * 100;
					// 	this.code = "-" + text + "%OFF";
					// 	this.codeMoney = promotion_code_money
					// 	this.codeTips = false;
					// 	this.codeMoneyShow = true;
					// 	this.promotion_code = data.promotion_code == "15" ? data.promotion_coupon : data.promotion_code;
					// 	this.promotion_coupon = data.promotion_coupon;
					// 	this.retailPrice = data.promotion_code_money;
					// }
					let text = data.discount.toFixed(2) * 100;
					this.code = "-" + text + "%OFF";
					this.codeMoney = data.promotion_money.toFixed(2);
					this.codeMoneyShow = true;
					this.codeTips = false;
					this.promotion_code = data.promotion_code == "15" ? data.promotion_coupon : data.promotion_code;
					this.promotion_coupon = data.promotion_coupon;
					this.retailPrice = data.goods_money.toFixed(2);
					this.shoppProductList.discount_price = (parseFloat(this.retailPrice) + parseFloat(transport.delivery_money) - parseFloat(codeMoney)).toFixed(2);
				}
			}).catch(err => {
				// console.log(err)
			})
		},
		// getCodeList() {
		// 	let list = localStorage.getItem("shoppProductCartSoyego");
		// 	var indexList = localStorage.getItem("shoppProductCartsiteIdSoyego").split(",");
		// 	var that = this;
		// 	getPlatformcouponTypelists().then( res =>{
		// 		let data = res.data;
		// 		let i = 0;
		// 		data.forEach( item =>{
		// 			if(item.platformcoupon_name == this.promoCode){
		// 				i = 1;
		// 				var coupon = item.platformcoupon_type_id;
		// 				// judgePlatformcoupon({ platformcoupon_type_id: coupon }).then( res1 =>{
		// 					// if(res1.code == 0 && res1.data == 0){
		// 						getPlatformcouponReceive({ platformcoupon_type_id: coupon, get_type: 2 }).then( res2 => {
		// 							console.log(res2)
		// 							if(res2.code == 0 && res2.data.is_exist == 1){
		// 								getPlatformcouponId({ platform_coupon_name: that.promoCode }).then( res3 => {
		// 									if(res3.code == 0 && res3.data){
		// 										if(res3.data.platformcoupon_id > 0){
		// 											that.couponId = res3.data.platformcoupon_id;
		// 												calculate({
		// 													barterRate: this.countrySoyego + "D",
		// 													cart_ids: list,
		// 													platform_coupon_id: res3.data.platformcoupon_id
		// 												}).then(res4 => {
		// 													if (res4.code == 0 && res4.data) {
		// 														let data = res4.data;
		// 														let total = (parseFloat(data.goods_money) + parseFloat(data.delivery_money)).toFixed(2);
		// 														if(data.pay_money == total){
		// 															this.code = "";
		// 															this.codeTips = true;
		// 															this.codeMoneyShow = false;
		// 														}else if(data.pay_money < total){
		// 															let text = (parseFloat(data.platform_coupon_money / data.goods_money).toFixed(2)) * 100;
		// 															this.code = "-" + text + "%OFF";
		// 															this.codeMoney = data.platform_coupon_money;
		// 															this.codeTips = false;
		// 															this.codeMoneyShow = true;
		// 														}
		// 													}
		// 												}).catch(err => {
		// 													// console.log(err)
		// 												})
		// 										}
		// 									}
		// 								}).catch( err => {
		// 									console.log(err)
		// 								})
										
		// 							}else{
		// 								this.code = "";
		// 								this.codeTips = true;
		// 								this.codeMoneyShow = false;
		// 							}
		// 						}).catch( err => {
		// 							console.log(err)
		// 						})
		// 					// }
		// 					// else{
		// 					// 	this.code = "";
		// 					// 	this.codeTips = true;
		// 					// 	this.codeMoneyShow = false;
		// 					// }
		// 					// console.log(res1)
		// 				// }).catch( err => {
		// 				// 	console.log(err)
		// 				// 	// this.$message.error("")
		// 				// })
		// 			}
		// 		})
		// 		if(i == 0){
		// 			this.code = "";
		// 			this.codeTips = true;
		// 			this.codeMoneyShow = false;
		// 		}
		// 	}).catch( err => {
		// 		console.log(err)
		// 	})
		// },
		// getCodeItem() {
		// 	let obj = JSON.parse(localStorage.getItem("shoppProductSoyego"));
		// 	var that = this;
		// 	getPlatformcouponTypelists().then( res =>{
		// 		let data = res.data;
		// 		let i = 0;
		// 		data.forEach( item =>{
		// 			if(item.platformcoupon_name == this.promoCode){
		// 				i = 1;
		// 				var coupon = item.platformcoupon_type_id;
		// 				// judgePlatformcoupon({ platformcoupon_type_id: coupon }).then( res1 =>{
		// 					// if(res1.code == 0 && res1.data == 0){
		// 						getPlatformcouponReceive({ platformcoupon_type_id: coupon, get_type: 2 }).then( res2 => {
		// 							console.log(res2)
		// 							if(res2.code == 0 && res2.data.is_exist == 1){
		// 								getPlatformcouponId({ platform_coupon_name: that.promoCode }).then( res3 => {
		// 									if(res3.code == 0 && res3.data){
		// 										if(res3.data.platformcoupon_id > 0){
		// 											that.couponId = res3.data.platformcoupon_id;
		// 												calculate({
		// 													barterRate: this.countrySoyego + "D",
		// 													sku_id: parseInt(obj.id),
		// 													num: parseInt(obj.num),
		// 													platform_coupon_id: res3.data.platformcoupon_id
		// 												}).then(res4 => {
		// 													if (res4.code == 0 && res4.data) {
		// 														let data = res4.data;
		// 														let total = (parseFloat(data.goods_money) + parseFloat(data.delivery_money)).toFixed(2);
		// 														if(data.pay_money == total){
		// 															this.code = "";
		// 															this.codeTips = true;
		// 															this.codeMoneyShow = false;
		// 														}else if(data.pay_money < total){
		// 															let text = (parseFloat(data.platform_coupon_money / data.goods_money).toFixed(2)) * 100;
		// 															this.code = "-" + text + "%OFF";
		// 															this.codeMoney = data.platform_coupon_money;
		// 															this.codeTips = false;
		// 															this.codeMoneyShow = true;
		// 														}
		// 													}
		// 												}).catch(err => {
		// 													// console.log(err)
		// 												})
		// 										}
		// 									}
		// 								}).catch( err => {
		// 									console.log(err)
		// 								})
										
		// 							}else{
		// 								this.code = "";
		// 								this.codeTips = true;
		// 								this.codeMoneyShow = false;
		// 							}
		// 						}).catch( err => {
		// 							console.log(err)
		// 						})
		// 					// }
		// 					// else{
		// 					// 	this.code = "";
		// 					// 	this.codeTips = true;
		// 					// 	this.codeMoneyShow = false;
		// 					// }
		// 					// console.log(res1)
		// 				// }).catch( err => {
		// 				// 	console.log(err)
		// 				// 	// this.$message.error("")
		// 				// })
		// 			}
		// 		})
		// 		if(i == 0){
		// 			this.code = "";
		// 			this.codeTips = true;
		// 			this.codeMoneyShow = false;
		// 		}
		// 	}).catch( err => {
		// 		console.log(err)
		// 	})
		// },
		codeClick() {
			if (this.$route.query.data == "cart") {
				this.getCodeList();
			} else {
				this.getCodeItem();
			}
			// if (this.promoCode == this.discountCode) {
				
			// 	this.code = "-15%OFF";
			// 	this.codeMoney = (parseFloat(this.retailPrice) - (parseFloat(this.retailPrice) * (0.85))).toFixed(2);
			// 	this.codeTips = false;
			// 	this.codeMoneyShow = true;
			// } else {
			// 	this.code = "";
			// 	this.codeTips = true;
			// 	this.codeMoneyShow = false;
			// }
		},
		paymentClick() {
			// var that = this;
			this.shoppPayList.forEach(item => {
				if (item.id == this.radioId) {
					localStorage.setItem('payAddressSoyego', JSON.stringify(item));
				} else if (item.is_default == 1) {
					localStorage.setItem('payAddressSoyego', JSON.stringify(item));
				}
			})
			if (JSON.parse(localStorage.getItem('payAddressSoyego')) == null) {
				this.$message.warning("Please select an address");
				return;
			}
			this.getOrderCreate();
		},
		getOrderCreate() {
			var that = this;
			// return;
			if (this.$route.query.data == "cart") {
				console.log(1111111111)
				let list = localStorage.getItem("shoppProductCartSoyego");
				let indexList = localStorage.getItem("shoppProductCartsiteIdSoyego").split(",");
				orderCreate({
					barterRate: this.countrySoyego,
					cart_ids: list,
					sku_id: indexList,
					num: this.num,
					promotion_code: this.promotion_code || this.promoCode,
					promotion_coupon: this.promotion_coupon,
					// platform_coupon_id: that.couponId
				}).then(res => {
					if (res.code == 0 && res.data) {
						var data = {
							order: res.data,
							payType: ""
						}
						// localStorage.setItem('orderNumberSoyego', res.data);
						if (this.radioShow == 0) {
							data.payType = "stripe";
							that.paymentPay(data);
						} else if (this.radioShow == 1) {
							data.payType = "paypal";
							that.paymentPay(data);
						} else if (this.radioShow < 0) {
							this.$message.warning("Please select payment method")
						}
						// this.paymentMethod.forEach(item => {
						// 	if (this.radioShow == item.radio) {
						// 		data.payType = item.pay_type.pay_type;
						// 		that.paymentPay(data);
						// 		// localStorage.setItem('paymentMethodSoyego', item.pay_type.pay_type);
						// 		// this.$router.push({ path: "/shoppPayment" });
						// 	} else if (this.radioShow < 0) {
						// 		this.$message.warning("Please select payment method")
						// 	} else if (this.radioShow == 0) {
						// 		data.payType = "creaditCard";
						// 		that.paymentPay(data);
						// 		// localStorage.setItem('paymentMethodSoyego', 'creaditCard');
						// 		// this.$router.push({ path: "/shoppPayment" });
						// 	}
						// })
					}
				}).catch(err => {
					this.$message.error(err.message);
					// console.log(err)
				})
			} else {
				console.log(22222222222222)
				let obj = JSON.parse(localStorage.getItem("shoppProductSoyego"));
				orderCreate({
					barterRate: obj.countrySoyego,
					num: obj.num,
					sku_id: obj.id,
					promotion_code: this.promotion_code || this.promoCode,
					promotion_coupon: this.promotion_coupon,
					// platform_coupon_id: that.couponId
				}).then(res => {
					console.log('======',res)
					if (res.code == 0 && res.data) {
						var data = {
							order: res.data,
							payType: ""
						}
						if (this.radioShow == 0) {
							data.payType = "stripe";
							that.paymentPay(data);
						} else if (this.radioShow == 1) {
							data.payType = "paypal";
							that.paymentPay(data);
						} else if (this.radioShow < 0) {
							this.$message.warning("Please select payment method");
						}
					}
				}).catch(err => {
					this.$message.error(err.message);
					// console.log(err)
				})
			}

		},
		paymentPay(data) {
			// let href = window.location.href;
			// console.log(href)
			pay({
				out_trade_no: data.order,
				pay_type: data.payType,
				return_url: encodeURIComponent('https://www.soyego.com/shoppComplete')
				// return_url: encodeURIComponent('http://localhost:8080/shoppComplete'),
			}).then(res => {
				console.log(res)
				if (res.code == 0 && res.data) {
					window.location.href = res.data.data;
					// this.$router.push(res.data.data)
				}
			}).catch(err => {
				this.$message.error(err.message);
				console.log(err)
			})
		},
		radioChange(item) {
			var that = this;
			this.addressLoad = true;
			setDefault({ id: item.id }).then( res =>{
				if(res.code == 0 && res.data){
					that.Default = 1;
					that.getMemberAddress();
					if (that.$route.query.data == "cart") {
						that.getList();
					} else {
						that.getItem();
					}
				}
			}).catch( err =>{
				// console.log(err)
			})
			
			// this.shoppPayList[i].is_default = 1;
			// console.log(this.shoppPayList[i])
			// memberaddressEdit({ ...data })
			// 	.then((res) => {
			// 		if (res.code == 0) {
			// 			// that.radioId = item.id;
			// 			that.getMemberAddress();
			// 			if (that.$route.query.data == "cart") {
			// 				that.getList();
			// 			} else {
			// 				that.getItem();
			// 			}
			// 			that.getPay();
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		this.$message.error(err.message)
			// 	})
		}
	}
}