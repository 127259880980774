<template>
    <div class="address-form">
        <div class="form">
            <div class="select">
                <el-select v-model="country" placeholder="Country">
                    <el-option v-for="item in options" :key="item.shortname" :label="item.shortname" :value="item.shortname"> </el-option>
                </el-select>
            </div>
            <div class="input"></div>
            <div class="select">
                <el-input v-model="city" placeholder="City"></el-input>
            </div>
            <div class="input">
                <el-input v-model="province" placeholder="Province/State"></el-input>
            </div>
            <div class="input">
                <el-input v-model="lastName" placeholder="Last Name"></el-input>
            </div>
            <div class="input">
                <el-input v-model="firstName" placeholder="First Name"></el-input>
            </div>
            <div>
                <el-input v-model="phone" placeholder="Enter your Phone Number"></el-input>
            </div>
            <div>
                <el-input v-model="state" placeholder="Enter your Street Address"></el-input>
            </div>
            <div>
                <el-input v-model="postalCode" placeholder="Enter your Zip/Postal Code"></el-input>
            </div>
            <div>
                <el-checkbox v-model="radio">Default</el-checkbox>
            </div>
        </div>
        <div class="btn">
            <button @click="ativeAddress()">Save Address</button>
        </div>
    </div>
</template>

<style>
.address-form .el-input__inner {
    border-radius: 0;
}
</style>
<style lang="scss" scoped>
.address-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    .form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
            width: 100%;
            padding-bottom: 20px;
        }
        .select {
            width: calc(50% - 10px);

            .el-select {
                width: 100%;
            }
        }
        .input {
            width: calc(50% - 10px);
        }
    }

    .btn {
        display: flex;
        justify-content: center;
        padding-top: 5px;

        button {
            outline: none;
            border: none;
            background: #330000;
            cursor: pointer;
            padding: 15px 40px;
            font-size: 16px;
            font-family: Montserrat,SF-UI;
            font-weight: 400;
            color: #ffffff;
        }
    }
}
</style>

<script>
import { addressCountryLists, memberaddressAdd, memberaddressEdit } from "@/api/pc"

export default {
    name: "shipingAddress",
    data: () => {
        return {
            country: "",
            options: [],
            city: "",
            province: "",
            lastName: "",
            firstName: "",
            phone: "",
            state: "",
            postalCode: "",
            radio: false,
            areaCode: "",
            id: "",
            countryId: 0
        }
    },
    props: ["addressAtive", "addressList"],
    watch: {
        $route: "fetchData",
        addressAtive: {
            deep: true,
            handler: function (val) {
                this.addressAtive = val
                this.fetchData()
            }
        },
        addressList: {
            deep: true,
            handler: function (val) {
                this.addressList = val
                this.fetchData()
            }
        }
    },
    components: {},
    created() {},
    mounted() {
        this.getCountry()
        this.ativeChange();
    },
    methods: {
        ativeChange() {
            if (this.addressAtive == 1) {
                this.getText();
            }else{
                this.getText1();
            }
        },
        fetchData() {
            this.$forceUpdate();
            this.ativeChange();
        },
        getCountry() {
            addressCountryLists()
                .then((res) => {
                    // console.log(res)
                    if (res.code == 0 && res.data) {
                        this.options = res.data;
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        getText() {
            this.country = this.addressList.country
            this.city = this.addressList.address
            this.province = this.addressList.province
            this.lastName = this.addressList.lastName
            this.firstName = this.addressList.name
            this.phone = this.addressList.telephone.split(" ")[1]
            this.state = this.addressList.full_address
            this.postalCode = this.addressList.coding
            this.radio = this.addressList.is_default == 0 ? false : true
            this.id = this.addressList.id
        },
        getText1() {
            this.country = ""
            this.city = ""
            this.province = ""
            this.lastName = ""
            this.firstName = ""
            this.phone = ""
            this.state = ""
            this.postalCode = ""
            this.radio = false
            this.id = ""
        },
        ativeAddress() {
            this.options.forEach((item) => {
                if (this.country == item.shortname) {
                    this.areaCode = "+" + parseInt(item.area_code);
                    this.countryId = item.id;
                }
            })
            let data = {
                country: this.country,
                district_id: this.countryId,
                province: this.province,
                address: this.city,
                lastName: this.lastName,
                name: this.firstName,
                mobile: this.areaCode + " " + this.phone,
                telephone: this.areaCode + " " + this.phone,
                full_address: this.state,
                coding: this.postalCode,
                is_default: this.radio ? 1 : 0,
                id: this.id
            }
            if (this.addressAtive == 0) {
                this.addAddress(data)
            } else if (this.addressAtive == 1) {
                // data.id = this.id;
                this.editAddress(data)
            }
        },
        addAddress(data) {
            var that = this;
            memberaddressAdd({ ...data })
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.$message({ message: "Added successfully", type: "success" });
                        that.$emit("closeDialog");
                        that.$emit("addAddress", res.code);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                    this.$message.error(err.message)
                })
        },
        editAddress(data) {
            var that = this;
            memberaddressEdit({ ...data })
                .then((res) => {
                   
                    if (res.code == 0 && res.data) {
                        this.$message({ message: "Modified successfully", type: "success" });
                        that.$emit("closeDialog");
                        that.$emit("addAddress", res.code);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                    this.$message.error(err.message)
                })
        }
    }
}
</script>