<template>
    <div class="shipingCoupons">
        <div class="list">
            <div :class="item.checked ? 'item checked' : 'item' " v-for="(item, index) in list" :key="index">
                <div class="save">
                    <span>${{item.price}}</span>
                    <p>SAVE</p>
                </div>
                <span>{{item.text}}</span>
                <p>{{item.time}}</p>
                <h5>NEW USER</h5>
                <el-radio v-model="radio" :label="index" @change="radioChange(item.price)">&nbsp;</el-radio>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "shipingCoupons",
	components: {
        
	},
	data: () => {
		return {
            radio: -1,
			list: [
                {
                    price: "10",
                    text: "For orders over US$100.00",
                    time: "02/01/2022 ~ 09/01/2022"
                },
                {
                    price: "10",
                    text: "For orders over US$100.00",
                    time: "02/01/2022 ~ 09/01/2022"
                },
                {
                    price: "10",
                    text: "For orders over US$100.00",
                    time: "02/01/2022 ~ 09/01/2022"
                }
            ]
		}
	},
	mounted() {
		
	},
	methods: {
		handleClose() {
            this.shipingAddress = false;
        },
        handleClose1() {
            this.coupons = false;
        },
        radioChange(val) {
            if(this.radio >= 0){
                this.$emit("radioClose", val);
            }
        }
	}
}
</script>

<style>
.shipingCoupons .el-radio{
    position: absolute;
    right: 10px;
    top: 15px;
}
.shipingCoupons .el-radio__input .el-radio__inner{
    background-color: rgba(29, 29, 29, 0.3);
    border-color: #FFFFFF;
    border-radius: 50%;
    width: 18px;
    height: 18px;  
}

.shipingCoupons .el-radio__input.is-checked .el-radio__inner,
.shipingCoupons .el-radio__input.is-indeterminate .el-radio__inner {
    border-color: #330000;
    background-color: #330000;
    color: #330000;
    width: 18px;
    height: 18px;
}
.shipingCoupons .el-radio__inner::after{
    left: 5px;
    top: 3px;
    content: "✔";
    background: transparent;
    color: #fff;
}
.shipingCoupons .el-radio__input.is-indeterminate .el-radio__inner::before{
    top: 7px;
}
.shipingCoupons .el-radio__input.is-checked + .el-radio__label{
    color: #330000;
}
.shipingCoupons .el-radio__input.is-focus .el-radio__inner{
    border-color: #FFFFFF;
    width: 18px;
    height: 18px;
}
</style>
<style lang="scss" scoped>
.shipingCoupons {
    width: 100%;

    .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 30px;
        
        .item {
            width: calc(50% - 10px);
            height: 144px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            position: relative;
            // cursor: pointer;
            background: url("~@/assets/images/goods/couponRadio.png") no-repeat;
            background-size: 100% 100%;

            .save {
                padding-left: 20px;
                padding-top: 22px;
                display: flex;

                span {
                    font-size: 20px;
                    font-family: Montserrat,SF-UI;
                    font-weight: bold;
                    color: #F34040;
                }

                p {
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: bold;
                    padding-top: 8px;
                    padding-left: 8px;
                    color: #F34040;
                }
            }

            > span {
                padding-left: 20px;
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: 400;
                color: #F34040;
            }

            > p {
                padding-left: 20px;
                padding-top: 20px;
                font-size: 12px;
                font-family: Montserrat,SF-UI;
                font-weight: 400;
                color: #E09696;
            }

            > h5 {
                font-size: 16px;
                font-family: Montserrat,SF-UI;
                font-weight: 400;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 24px;
                color: #330000;
            }
        }
    }
}
</style>